import { Helmet } from 'react-helmet-async';

interface HeadProps {
  title?: string;
  description?: string;
  keywords?: string[];
  canonicalUrl?: string;
  imageUrl?: string;
}

export function Head({
  title = 'Markdown Live Preview - Free Online Editor & Real-Time Preview',
  description = 'Free online Markdown editor with instant live preview. Write, edit, and preview markdown in real-time. Perfect for creating documentation, README files, and viewing markdown files in browser.',
  keywords = [
    // High volume primary keywords
    'markdown cheat sheet',
    'markdown table',
    'markdown editor',
    'markdown hyperlink',
    'markdown link',
    // Core formatting keywords
    'markdown bold',
    'markdown strikethrough',
    'markdown formatting',
    'markdown code block',
    'markdown italics',
    'markdown underline',
    // Preview and viewer keywords
    'markdown live preview',
    'markdown viewer',
    'markdown preview',
    'markdown online preview',
    // Technical and math features
    'markdown latex',
    'markdown math',
    'markdown equations',
    'latex markdown',
    'markdown math editor',
    // Image and media keywords
    'markdown image',
    'markdown with images',
    // File conversion keywords
    'markdown to html',
    // Github and README focused
    'github markdown preview',
    'readme markdown preview',
    'readme editor online',
    'readme markdown editor',
    'markdown github editor',
    'online readme preview',
    // Editor features
    'markdown formatter',
    'markdown editor online',
    'markdown table editor',
  ],
  canonicalUrl = 'https://markdownlivepreview.org',
  imageUrl = 'https://markdownlivepreview.org/assets/images/preview-image.png'
}: HeadProps) {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Search Engine Verification */}
      <meta 
        name="google-site-verification" 
        content="_yM3X6q3dFGfLU__kNz6BCqxkcG9osXSdzwcqy-t0Gk" 
      />
      <meta 
        name="msvalidate.01" 
        content="532BEE359C11971A357D2970936E17D6"
      />
      
      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:site_name" content="Markdown Live Preview" />
      
      {/* Open Graph Image Details */}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      
      {/* Additional Meta Tags */}
      <meta name="author" content="Markdown Live Preview" />
      <meta name="robots" content="index, follow, max-image-preview:large" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      
      {/* Language Alternatives - Fixed hrefLang casing */}
      <link rel="alternate" href={canonicalUrl} hrefLang="x-default" />
      <link rel="alternate" href={canonicalUrl} hrefLang="en" />
      
      {/* Enhanced FAQ Schema for SEO */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "What is Markdown Live Preview?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Markdown Live Preview is an advanced online editor providing instant preview of markdown content with LaTeX math support, emoji shortcuts, and GitHub-style formatting. It's perfect for writing documentation, README files, technical content, and mathematical equations."
            }
          }, {
            "@type": "Question",
            "name": "What special features does Markdown Live Preview support?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our editor supports LaTeX mathematical equations, emoji shortcuts, GitHub-flavored markdown tables, syntax highlighting, and automatic table formatting. It's particularly useful for technical documentation and README files."
            }
          }, {
            "@type": "Question",
            "name": "Can I use this for creating GitHub README files?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, our editor is perfect for creating and previewing GitHub README files. It supports all GitHub-flavored markdown features including tables, task lists, and code formatting."
            }
          }, {
            "@type": "Question",
            "name": "Does it support mathematical equations?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, we fully support LaTeX mathematical equations using KaTeX, making it perfect for technical and scientific writing."
            }
          }]
        })}
      </script>
    </Helmet>
  );
}