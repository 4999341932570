export const defaultMarkdown = `# Welcome to Markdown Live Preview! ✨

Transform your text with our real-time markdown editor - see your changes instantly as you type. Perfect for creating documentation, README files, and viewing markdown in browser.

## Quick Examples

Here's a quick tour of what you can do with our markdown editor:

### Text Formatting

A paragraph with *emphasis* and **strong importance**.

This paragraph includes **bold text**, *italics*, and ***bold italics***.

Some *emphasis* and <strong>strong</strong> HTML tags work too!

You can use ~~strikethrough~~ for outdated info.

This ~is not~ strikethrough (single tilde), but ~~this is~~ (double tilde)!

### Links & URLs

URLs are automatically linked: https://github.com/
Or create [custom links](https://github.com/ "GitHub Homepage")
Reference-style [links][ref] are supported too!

[ref]: https://github.com/ "GitHub Home"

### Lists & Tasks

1. Ordered list item
2. Another item
   - Nested unordered item
   - Another nested item
3. Back to ordered

- [x] Task list item
- [x] Completed task
- [ ] Pending task
  - [x] Nested task

### Code Examples

Inline code: \`const message = "Hello, World!";\`

Code blocks with syntax highlighting:

\`\`\`javascript
// JavaScript Example
const hello = 'world';
console.log(hello);

function greet(name) {
  return \`Hello, \${name}!\`;
}
\`\`\`

\`\`\`python
# Python Example
def calculate_average(numbers):
    return sum(numbers) / len(numbers)

data = [1, 2, 3, 4, 5]
avg = calculate_average(data)
print(f"Average: {avg}")
\`\`\`

### Tables

| Feature | Syntax | Example | Status |
|---------|:-------|:-------:|-------:|
| Bold | \`**text**\` | **text** | ✅ |
| Italic | \`*text*\` | *text* | ✅ |
| Strike | \`~~text~~\` | ~~text~~ | ✅ |

### Quotes & Notes

> Block quotes are useful for highlighting important information
>
> They can span multiple paragraphs
>> And can be nested for dialogue or references

### Mathematical Expressions

Inline math works like this: $E = mc^2$

Block math equations:
$$
\\begin{align*}
\\frac{1}{n} \\sum_{i=1}^{n} x_i &= \\bar{x} \\\\
s^2 &= \\frac{1}{n-1} \\sum_{i=1}^{n} (x_i - \\bar{x})^2
\\end{align*}
$$

### Emojis & Icons

Emojis add visual elements to your text:
- Project launch :rocket:
- Important note :warning:
- Great job :+1:
- Debugging help :bug:

Hello :dog:! Look at this cute doggo with thumbs up :dog::+1:

### Image

![Markdown Live Preview, the online Markdown editor with advanced preview features](/assets/images/markdown.png)

### Autolink literals

www.example.com, https://example.com, and contact@example.com.

### Footnote

This is the first footnote.[^1]

[^1]: Big note.

### Advanced HTML Support

<div align="center">
  <h3>Centered content with HTML</h3>
  <p>Use HTML when you need more control</p>
</div>

<details>
<summary>Click to expand!</summary>

- Details and summary elements work
- Great for collapsible sections
- Hide implementation details
</details>

## Tips for Great Markdown

1. Structure your content with headers
2. Use lists for steps and items
3. Add code blocks with syntax highlighting
4. Include tables for structured data
5. Use equations for mathematical content
6. Add emojis sparingly for visual cues

---

<div align="center">

### Ready to Create Great Content?

Start writing in the editor (left) and see the rendered preview (right)!

[Get Started](#) • [View Examples](https://markdownlivepreview.dev/examples) • [Read Docs](https://markdownlivepreview.dev/docs)

</div>

---

Need help? Type something in the editor and watch it transform in real-time! 🎨`;