import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="h-full overflow-auto bg-slate-100">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <Link 
          to="/" 
          className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6"
        >
          ← Back to Editor
        </Link>

        <h1 className="text-3xl font-bold text-slate-900 mb-8">Privacy Policy</h1>
        
        <div className="prose prose-slate max-w-none">
          <p className="lead">At Markdown Live Preview, we take your privacy seriously. This Privacy Policy explains how we collect, use, and protect your information.</p>

          <h2>Data Collection</h2>
          <p>We minimize data collection to essential information only:</p>
          <ul>
            <li>Your content remains in your browser's localStorage and is never sent to our servers</li>
            <li>We use anonymous analytics data to improve our service</li>
            <li>We do not use cookies for tracking or advertising</li>
          </ul>

          <h2>Local Storage</h2>
          <p>Your markdown content is stored exclusively in your browser's localStorage:</p>
          <ul>
            <li>Content remains on your device</li>
            <li>Content is not transmitted to our servers</li>
            <li>Content persists until you clear your browser data</li>
          </ul>

          <h2>Analytics</h2>
          <p>We use privacy-focused analytics to understand how our service is used:</p>
          <ul>
            <li>Page views and basic usage statistics</li>
            <li>No personal identification</li>
            <li>No cross-site tracking</li>
          </ul>

          <h2>Third-Party Services</h2>
          <p>We use the following third-party services:</p>
          <ul>
            <li>Vercel for hosting</li>
            <li>Simple Analytics for privacy-focused analytics</li>
          </ul>

          <h2>Data Retention</h2>
          <p>Since all content is stored locally:</p>
          <ul>
            <li>We don't retain any user content</li>
            <li>Analytics data is anonymized and retained for 30 days</li>
            <li>You can clear your content by clearing your browser data</li>
          </ul>

          <h2>Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your local data</li>
            <li>Delete your local data</li>
            <li>Export your content</li>
            <li>Use the service without tracking (by using an ad blocker)</li>
          </ul>

          <h2>Changes to This Policy</h2>
          <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.</p>

          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:hello@markdownlivepreview.org">hello@markdownlivepreview.org</a></p>

          <p className="text-sm text-slate-500 mt-8">Last updated: {new Date().toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;