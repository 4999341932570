import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import AppRoutes from './routes';
import ScrollToTop from './components/ScrollToTop';

// Initialize GA4
const GA_MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID || 'G-PGPX8G4SMJ';
ReactGA.initialize(GA_MEASUREMENT_ID);

function App() {
  return (
    <Router>
      <HelmetProvider>
        <ScrollToTop />
        <AppRoutes />
        <Toaster
          position="bottom-right"
          toastOptions={{
            className: 'react-hot-toast',
            success: {
              iconTheme: {
                primary: '#10b981',
                secondary: '#ffffff',
              },
            },
            error: {
              iconTheme: {
                primary: '#ef4444',
                secondary: '#ffffff',
              },
            },
            style: {
              background: '#18181b',
              color: '#ffffff',
              padding: '12px',
              borderRadius: '6px',
            },
          }}
        />
      </HelmetProvider>
    </Router>
  );
}

export default App;