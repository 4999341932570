import { Editor } from '../components/Editor';
import { Preview } from '../components/Preview';
import { useState } from 'react';
import { defaultMarkdown } from '../defaultMarkdown';
import { Head } from '../components/Head';

function EditorPage() {
  const [markdown, setMarkdown] = useState(defaultMarkdown);
  const [syncScroll, setSyncScroll] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [editorScrollInfo, setEditorScrollInfo] = useState<{
    scrollTop: number;
    scrollHeight: number;
    clientHeight: number;
  } | null>(null);

  const handleEditorScroll = (scrollInfo: {
    scrollTop: number;
    scrollHeight: number;
    clientHeight: number;
  }) => {
    setEditorScrollInfo(scrollInfo);
  };

  // Targeting top queries from Search Console
  const pageTitle = "Markdown Live Preview - Free Online Editor with Real-Time Preview";
  const pageDescription = "Free online Markdown editor with instant live preview. Perfect for viewing markdown files in browser, real-time preview markdown, and creating documentation. Simple, fast, and free markdown live editor.";
  const seoKeywords = [
    'markdown live preview',
    'markdown online preview',
    'markdown preview online',
    'online markdown preview',
    'online markdown viewer',
    'online markdown renderer',
    'markdown online test',
    'readme preview online',
    'live markdown editor',
    'markdown live',
    'online md preview',
    'test markdown online',
    'markdown preview',
    'markdown renderer online',
    'markdown ide',
    'markdownlive',
    'live preview',
    'preview markdown online'
  ];

  return (
    <>
      <Head
        title={pageTitle}
        description={pageDescription}
        keywords={seoKeywords}
      />
      <main 
        className={`flex-1 overflow-hidden bg-gray-100 ${isFullscreen ? 'p-0' : 'p-2'}`}
        // Adding semantic HTML attributes for SEO
        role="main"
        aria-label="Markdown editor and preview area"
      >
        <div 
          className={`h-full ${isFullscreen ? '' : 'grid grid-cols-1 md:grid-cols-2 gap-2'}`}
          // Adding semantic HTML attributes
          role="application"
          aria-label="Markdown live preview interface"
        >
          <div className="h-full overflow-auto">
            <Editor
              markdown={markdown}
              onMarkdownChange={setMarkdown}
              syncScroll={syncScroll}
              onSyncScrollChange={setSyncScroll}
              onScroll={handleEditorScroll}
              isFullscreen={isFullscreen}
              onFullscreenToggle={() => setIsFullscreen(!isFullscreen)}
              defaultMarkdown={defaultMarkdown}
            />
          </div>
          {!isFullscreen && (
            <div 
              className="h-full overflow-auto"
              role="complementary"
              aria-label="Live markdown preview"
            >
              <Preview
                markdown={markdown}
                syncScroll={syncScroll}
                editorScrollInfo={editorScrollInfo}
              />
            </div>
          )}
        </div>
      </main>
    </>
  );
}

// Adding display name for better debugging
EditorPage.displayName = 'EditorPage';

export default EditorPage;