import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Heart } from 'lucide-react';
import toast from 'react-hot-toast';

interface FooterProps {
  className?: string;
}

interface FooterLink {
  to: string;
  label: string;
  isExternal?: boolean;
}

const FOOTER_LINKS: FooterLink[] = [
  { to: '/legal', label: 'Legal' },
  { to: '/privacy', label: 'Privacy Policy' },
  { to: '/gdpr', label: 'GDPR' }
];

const EMAIL = 'hello@markdownlivepreview.org';
const COMPANY_URL = 'https://digitalpro.dev';

export function Footer({ className = '' }: FooterProps) {
  const [isCopying, setIsCopying] = useState(false);

  const handleEmailClick = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    if (isCopying) return;

    try {
      setIsCopying(true);
      await navigator.clipboard.writeText(EMAIL);
      
      toast.success('Email copied to clipboard!', {
        duration: 2000,
        style: {
          background: '#18181b',
          color: '#fff',
        },
      });
    } catch (error: unknown) {
      console.error('Failed to copy email:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to copy email');
    } finally {
      setTimeout(() => setIsCopying(false), 1000);
    }
  }, [isCopying]);

  return (
    <footer className={`bg-slate-800 border-t border-slate-700 text-slate-400 h-8 flex items-center ${className}`}>
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          {/* Legal Links */}
          <div className="flex gap-2 sm:gap-4 text-xs">
            {FOOTER_LINKS.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className="hover:text-slate-200 transition-colors px-2 py-1 rounded"
              >
                {link.label}
              </Link>
            ))}
            <button
              onClick={handleEmailClick}
              className={`text-xs px-2 py-1 rounded transition-colors hover:text-slate-200
                ${isCopying ? 'cursor-wait opacity-70' : ''}`}
              disabled={isCopying}
              aria-label={isCopying ? 'Copying email...' : 'Copy email to clipboard'}
            >
              <span className="hidden sm:inline">{EMAIL}</span>
              <span className="sm:hidden">Contact</span>
            </button>
          </div>

          {/* Attribution */}
          <div className="absolute left-1/2 -translate-x-1/2 hidden sm:flex items-center gap-1.5 text-xs">
            <span>Made with</span>
            <Heart 
              size={12} 
              className="fill-indigo-500 text-indigo-500" 
              aria-label="love"
            />
            <span>by</span>
            <a 
              href={COMPANY_URL}
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-slate-300 hover:text-slate-200 transition-colors"
              aria-label="Visit DigitalPro website"
            >
              DigitalPro
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;