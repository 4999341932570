import { Link } from 'react-router-dom';

function Legal() {
  return (
    <div className="h-full overflow-auto bg-slate-100">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <Link 
          to="/" 
          className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6"
        >
          ← Back to Editor
        </Link>

        <h1 className="text-3xl font-bold text-slate-900 mb-8">Terms of Service</h1>
        
        <div className="prose prose-slate max-w-none">
          <h2>1. Terms</h2>
          <p>By accessing the website at <a href="https://markdownlivepreview.org">markdownlivepreview.org</a>, you are agreeing to be bound by these terms of service and agree that you are responsible for compliance with any applicable local laws.</p>

          <h2>2. Use License</h2>
          <p>The content on Markdown Live Preview is intended for personal and non-commercial use. Your content is stored locally in your browser and is not transmitted to our servers.</p>

          <h2>3. Disclaimer</h2>
          <p>The materials on Markdown Live Preview are provided on an 'as is' basis. Markdown Live Preview makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

          <h2>4. Limitations</h2>
          <p>In no event shall Markdown Live Preview or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Markdown Live Preview.</p>

          <h2>5. Content Storage</h2>
          <p>All content is stored locally in your browser's localStorage. We do not store or process any of your content on our servers. You are responsible for backing up your own content.</p>

          <h2>6. Revisions and Errata</h2>
          <p>We reserve the right to update these terms of service at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>

          <h2>7. Links</h2>
          <p>Markdown Live Preview has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Markdown Live Preview of the site. Use of any such linked website is at the user's own risk.</p>

          <h2>8. Governing Law</h2>
          <p>These terms and conditions are governed by and construed in accordance with the laws and you irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>

          <h2>Contact Information</h2>
          <p>If you have any questions about these Terms of Service, please contact us at: <a href="mailto:hello@markdownlivepreview.org">hello@markdownlivepreview.org</a></p>

          <p className="text-sm text-slate-500 mt-8">Last updated: {new Date().toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
}

export default Legal;