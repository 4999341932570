// src/utils/storage.ts
const STORAGE_KEY = 'markdown-content';
const MAX_CHARS = 100000;

export const storageUtils = {
  saveContent: (content: string): boolean => {
    if (content.length > MAX_CHARS) {
      return false;
    }
    try {
      localStorage.setItem(STORAGE_KEY, content);
      return true;
    } catch (error) {
      console.error('Storage error:', error);
      return false;
    }
  },

  loadContent: (): string | null => {
    try {
      return localStorage.getItem(STORAGE_KEY);
    } catch (error) {
      console.error('Storage error:', error);
      return null;
    }
  },

  clearContent: () => {
    try {
      localStorage.removeItem(STORAGE_KEY);
      return true;
    } catch (error) {
      console.error('Storage error:', error);
      return false;
    }
  }
};