import { useRef, useEffect, useState, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import remarkRehype from 'remark-rehype';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import remarkEmoji from 'remark-emoji';
import toast from 'react-hot-toast';
import { PreviewToolbar } from './PreviewToolbar';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import stringWidth from 'string-width';

// Import required styles
import 'katex/dist/katex.min.css';

interface PreviewProps {
  markdown: string;
  syncScroll?: boolean;
  editorScrollInfo?: {
    scrollTop: number;
    scrollHeight: number;
    clientHeight: number;
  } | null;
}

interface PreviewStats {
  words: number;
  chars: number;
  lines: number;
}

export function Preview({ markdown, syncScroll, editorScrollInfo }: PreviewProps) {
  const previewRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [copyTextInProgress, setCopyTextInProgress] = useState(false);
  const [copyHtmlInProgress, setCopyHtmlInProgress] = useState(false);
  const isScrollingRef = useRef(false);
  const [stats, setStats] = useState<PreviewStats>({ words: 0, chars: 0, lines: 0 });

  // Stats calculation effect
  useEffect(() => {
    if (!contentRef.current) return;

    const content = contentRef.current.textContent || '';
    const lines = content.split('\n').length;
    const chars = content.length;
    const words = content.trim() ? content.trim().split(/\s+/).filter(word => word.length > 0).length : 0;

    setStats({ words, chars, lines });
  }, [markdown]);

  // Scroll sync effect
  useEffect(() => {
    if (!syncScroll || !editorScrollInfo || !previewRef.current || isScrollingRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = editorScrollInfo;
    const previewElement = previewRef.current;
    
    const editorScrollPercentage = scrollTop / (scrollHeight - clientHeight);
    const targetScrollTop = Math.round(editorScrollPercentage * (previewElement.scrollHeight - previewElement.clientHeight));
    
    if (!previewElement.matches(':hover')) {
      previewElement.scrollTop = targetScrollTop;
    }
  }, [syncScroll, editorScrollInfo]);

  // Copy text functionality
  const copyText = useCallback(async () => {
    if (copyTextInProgress || !contentRef.current) return;

    try {
      setCopyTextInProgress(true);
      const renderedText = contentRef.current.textContent || '';
      await navigator.clipboard.writeText(renderedText);
      toast.success('Preview text copied to clipboard', {
        duration: 2000,
        style: {
          background: '#18181b',
          color: '#fff',
        },
      });
    } catch (error: unknown) {
      console.error('Failed to copy text:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to copy text');
    } finally {
      setTimeout(() => setCopyTextInProgress(false), 1000);
    }
  }, [copyTextInProgress]);

  // Copy HTML functionality
  const copyHtml = useCallback(async () => {
    if (copyHtmlInProgress || !contentRef.current) return;

    try {
      setCopyHtmlInProgress(true);
      const htmlContent = contentRef.current.innerHTML;
      const cleanHtml = htmlContent
        .replace(/\sclass="[^"]*"/g, '')
        .replace(/\sdata-[^=]*="[^"]*"/g, '')
        .trim();

      await navigator.clipboard.writeText(cleanHtml);
      toast.success('HTML copied to clipboard', {
        duration: 2000,
        style: {
          background: '#18181b',
          color: '#fff',
        },
      });
    } catch (error: unknown) {
      console.error('Failed to copy HTML:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to copy HTML');
    } finally {
      setTimeout(() => setCopyHtmlInProgress(false), 1000);
    }
  }, [copyHtmlInProgress]);

  // Download text functionality
  const downloadText = useCallback(() => {
    if (!contentRef.current) {
      toast.error('No content to download');
      return;
    }

    try {
      const renderedText = contentRef.current.textContent || '';
      const blob = new Blob([renderedText], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      a.download = `document-${timestamp}.txt`;
      a.click();
      URL.revokeObjectURL(url);
      toast.success('Document downloaded successfully');
    } catch (error: unknown) {
      console.error('Failed to download text:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to download document');
    }
  }, []);

  return (
    <div className="flex flex-col h-[40vh] md:h-full">
      <PreviewToolbar
        onCopyText={copyText}
        onCopyHtml={copyHtml}
        onDownloadText={downloadText}
        copyTextInProgress={copyTextInProgress}
        copyHtmlInProgress={copyHtmlInProgress}
        hasContent={Boolean(markdown.trim())}
      />
  
      <div
        ref={previewRef}
        className="flex-1 bg-[var(--preview-bg)] text-[var(--preview-text)] p-4 overflow-y-auto preview-scrollbar"
        onMouseEnter={() => {
          isScrollingRef.current = true;
        }}
        onMouseLeave={() => {
          isScrollingRef.current = false;
        }}
      >
        <div
          ref={contentRef}
          className="prose prose-sm sm:prose lg:prose-lg max-w-none dark:prose-invert"
        >
          <ReactMarkdown
            remarkPlugins={[
              [remarkGfm, { 
                singleTilde: false,  // Disable single tilde strikethrough
                stringLength: stringWidth  // Improve table alignment for full-width chars and emoji
              }],
              remarkMath,
              remarkRehype,
              [remarkEmoji, { 
                accessible: true,
                padSpaceAfter: true,  // Add space after emoji for better readability
                emoticon: false  // Set true to support emoticon shortcodes
              }]
            ]}
            rehypePlugins={[
              rehypeKatex,
              rehypeRaw,
              rehypeSlug,
              [rehypeAutolinkHeadings, {
                behavior: 'wrap',
                properties: { className: ['anchor'] }
              }]
            ]}
            components={{
              code({className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '')
                return match ? (
                  <SyntaxHighlighter
                    style={oneDark}
                    language={match[1]}
                    PreTag="div"
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              }
            }}
          >
            {markdown}
          </ReactMarkdown>
        </div>
      </div>
  
      <div className="bg-gray-200 p-2 rounded-b text-sm flex justify-between items-center">
        <div>
          {stats.words.toLocaleString()} words | {stats.chars.toLocaleString()} characters | {stats.lines.toLocaleString()} lines
        </div>
      </div>
    </div>
  );
}

export default Preview;