import { Link } from 'react-router-dom';

function GDPR() {
  return (
    <div className="h-full overflow-auto bg-slate-100">
      <div className="max-w-3xl mx-auto px-4 py-8">
        <Link 
          to="/" 
          className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-6"
        >
          ← Back to Editor
        </Link>

        <h1 className="text-3xl font-bold text-slate-900 mb-8">GDPR Compliance</h1>
        
        <div className="prose prose-slate max-w-none">
          <h2>Overview</h2>
          <p>This document outlines how Markdown Live Preview complies with the General Data Protection Regulation (GDPR) and protects user rights.</p>

          <h2>Data Controller</h2>
          <p>For any personal data processed in connection with this service:</p>
          <p>Markdown Live Preview<br />
          Email: <a href="mailto:hello@markdownlivepreview.org">hello@markdownlivepreview.org</a></p>

          <h2>Data Processing</h2>
          <h3>Local Storage</h3>
          <p>Your content is processed and stored exclusively in your browser's localStorage:</p>
          <ul>
            <li>No content is transmitted to our servers</li>
            <li>Content remains under your control</li>
            <li>Content can be deleted at any time</li>
          </ul>

          <h3>Analytics</h3>
          <p>We use privacy-focused analytics that:</p>
          <ul>
            <li>Collect anonymous usage data</li>
            <li>Do not track individual users</li>
            <li>Can be blocked without affecting functionality</li>
          </ul>

          <h2>Your Rights Under GDPR</h2>
          <p>You have the following rights:</p>
          <ul>
            <li>Right to access</li>
            <li>Right to rectification</li>
            <li>Right to erasure</li>
            <li>Right to restrict processing</li>
            <li>Right to data portability</li>
            <li>Right to object</li>
          </ul>

          <h2>Data Security</h2>
          <p>We ensure data security through:</p>
          <ul>
            <li>Client-side only processing</li>
            <li>No server-side storage</li>
            <li>HTTPS encryption</li>
            <li>Regular security updates</li>
          </ul>

          <h2>Data Protection Officer</h2>
          <p>Given the nature of our service (client-side only), we do not process personal data on our servers. However, for GDPR-related inquiries, please contact:</p>
          <p><a href="mailto:hello@markdownlivepreview.org">hello@markdownlivepreview.org</a></p>

          <h2>Data Processing Agreement</h2>
          <p>Since we do not process any data on our servers, no Data Processing Agreement is necessary. Your content remains in your browser's localStorage and is under your control.</p>

          <h2>Changes to This Policy</h2>
          <p>We will notify users of any changes to our GDPR compliance policy through our website.</p>

          <h2>Contact Information</h2>
          <p>For any GDPR-related queries, please contact us at: <a href="mailto:hello@markdownlivepreview.org">hello@markdownlivepreview.org</a></p>

          <p className="text-sm text-slate-500 mt-8">Last updated: {new Date().toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
}

export default GDPR;