import { Zap } from 'lucide-react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="bg-gray-800 text-white p-4">
      <div className="container mx-auto">
        <Link 
          to="/" 
          className="flex items-center hover:opacity-90 transition-opacity"
          aria-label="Go to homepage"
        >
          <Zap className="w-8 h-8 mr-2" />
          <h1 className="text-2xl font-bold">Markdown Live Preview</h1>
        </Link>
      </div>
    </header>
  );
}

export default Header;