import { Routes, Route } from 'react-router-dom';
import EditorPage from "../pages/EditorPage";
import Legal from "../pages/legal/Legal";
import PrivacyPolicy from "../pages/legal/PrivacyPolicy";
import GDPR from "../pages/legal/GDPR";
import Header from "../components/Header";
import { Footer } from '../components/Footer';

function AppRoutes() {
  return (
    <div className="fixed inset-0 flex flex-col">
      <Header />
      <Routes>
        <Route path="/" element={<EditorPage />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/gdpr" element={<GDPR />} />
        <Route path="*" element={<div>404 - Page Not Found</div>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default AppRoutes;