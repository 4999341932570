import { useRef, useEffect, useState, useCallback } from 'react';
import toast from 'react-hot-toast';
import { EditorToolbar } from './EditorToolbar';
import { storageUtils } from '../utils/storage';

interface EditorProps {
  markdown: string;
  onMarkdownChange: (value: string) => void;
  syncScroll: boolean;
  onSyncScrollChange: (value: boolean) => void;
  onScroll: (scrollInfo: { scrollTop: number; scrollHeight: number; clientHeight: number }) => void;
  isFullscreen: boolean;
  onFullscreenToggle: () => void;
  defaultMarkdown: string;
}

export function Editor({
  markdown,
  onMarkdownChange,
  syncScroll,
  onSyncScrollChange,
  onScroll,
  isFullscreen,
  onFullscreenToggle,
  defaultMarkdown,
}: EditorProps) {
  const editorRef = useRef<HTMLTextAreaElement>(null);
  const [copyInProgress, setCopyInProgress] = useState(false);
  const lastSavedRef = useRef<string>(markdown);

  // Scroll sync handler
  useEffect(() => {
    const handleScroll = () => {
      if (!editorRef.current || !syncScroll) return;
      
      const { scrollTop, scrollHeight, clientHeight } = editorRef.current;
      onScroll({ scrollTop, scrollHeight, clientHeight });
    };

    const editor = editorRef.current;
    if (editor) {
      editor.addEventListener('scroll', handleScroll);
      return () => editor.removeEventListener('scroll', handleScroll);
    }
  }, [syncScroll, onScroll]);

  // Auto-save effect with storage limits
  useEffect(() => {
    const saveResult = storageUtils.saveContent(markdown);
    if (saveResult) {
      lastSavedRef.current = markdown;
    } else if (markdown.length > 100000) {
      toast.error('Content exceeds maximum length of 100,000 characters');
    } else {
      console.error('Failed to auto-save');
      toast.error('Failed to save content');
    }
  }, [markdown]);

  const copyToClipboard = useCallback(async () => {
    if (copyInProgress || !markdown.trim()) return;

    try {
      setCopyInProgress(true);
      await navigator.clipboard.writeText(markdown);
      toast.success('Content copied to clipboard', {
        duration: 2000,
        style: {
          background: '#18181b',
          color: '#fff',
        },
      });
    } catch (error: unknown) {
      console.error('Failed to copy content:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to copy content');
    } finally {
      setTimeout(() => setCopyInProgress(false), 1000);
    }
  }, [markdown, copyInProgress]);

  const handleClear = useCallback(() => {
    if (!markdown.trim()) {
      toast.error('Editor is already empty');
      return;
    }

    if (window.confirm('Are you sure you want to clear all content? This action cannot be undone.')) {
      onMarkdownChange('');
      storageUtils.clearContent();
      toast.success('Content cleared successfully');
    }
  }, [markdown, onMarkdownChange]);

  const handleReset = useCallback(() => {
    if (markdown === defaultMarkdown) {
      toast.error('Content is already at default state');
      return;
    }

    if (window.confirm('Are you sure you want to reset to the default content? This action cannot be undone.')) {
      onMarkdownChange(defaultMarkdown);
      toast.success('Content reset to default');
    }
  }, [markdown, defaultMarkdown, onMarkdownChange]);

  const downloadMarkdown = useCallback(() => {
    if (!markdown.trim()) {
      toast.error('No content to download');
      return;
    }

    try {
      const blob = new Blob([markdown], { type: 'text/markdown' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      a.download = `document-${timestamp}.md`;
      a.click();
      URL.revokeObjectURL(url);
      toast.success('Document downloaded successfully');
    } catch (error: unknown) {
      console.error('Failed to download markdown:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to download document');
    }
  }, [markdown]);

  // Stats calculation
  const stats = {
    words: markdown.trim().split(/\s+/).filter(word => word.length > 0).length,
    chars: markdown.length,
    lines: markdown.split('\n').length,
  };

  return (
    <div className={`
      flex flex-col
      ${isFullscreen 
        ? 'fixed inset-0 z-50 bg-[var(--editor-bg)] p-4' 
        : 'h-[40vh] md:h-full'
      }
    `}>
      <EditorToolbar
        syncScroll={syncScroll}
        onSyncScrollChange={onSyncScrollChange}
        onClear={handleClear}
        onReset={handleReset}
        onCopy={copyToClipboard}
        onDownload={downloadMarkdown}
        isFullscreen={isFullscreen}
        onFullscreenToggle={onFullscreenToggle}
        copyInProgress={copyInProgress}
        hasContent={markdown.trim().length > 0}
      />

      <textarea
        ref={editorRef}
        value={markdown}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue.length <= 100000) {
            onMarkdownChange(newValue);
          } else {
            toast.error('Maximum character limit reached (100,000)');
          }
        }}
        className="flex-1 w-full bg-[var(--editor-bg)] text-[var(--editor-text)] p-4 font-mono resize-none outline-none overflow-y-auto editor-scrollbar"
        spellCheck={false}
        placeholder="Type your markdown here..."
        aria-label="Markdown editor"
        maxLength={100000}
      />

      <div className="bg-gray-700 p-2 rounded-b text-white text-sm flex justify-between items-center">
        <div>
          {stats.words.toLocaleString()} words | {stats.chars.toLocaleString()} characters | {stats.lines.toLocaleString()} lines
        </div>
        {markdown !== lastSavedRef.current && (
          <div className="text-yellow-400 text-xs">Unsaved changes</div>
        )}
      </div>
    </div>
  );
}

export default Editor;